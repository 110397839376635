import { createContext, useContext, useState, useEffect } from "react";
import { Bairro, SetorAtendimento, Profissional } from "../../api/api";
import { useAuth } from "../Auth/AuthContext";
import { useMessage } from "../Message/MessageContext";

const initialData = {
  bairros: [],
  setoresAtendimento: [],
  profissionais: [],
};

export const InitContext = createContext(initialData);

export function useInit() {
  return useContext(InitContext);
}

export function InitProvider({ children }) {
  const [data, setData] = useState(initialData);
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  const { setError } = useMessage();

  const check = ({ status, value, reason }) => {
    if (status !== "fulfilled") {
      console.error(reason);
      return [];
    }

    return value.data;
  };

  const load = async () => {
    setIsLoading(true);
    try {
      const response = await Promise.allSettled([
        Bairro(user.jwt).get(),
        SetorAtendimento(user.jwt).get(),
        Profissional(user.jwt).get(),
      ]);

      setData({
        bairros: check(response[0]),
        setoresAtendimento: check(response[1]),
        profissionais: check(response[2])
          .filter((p) => !p.user || (!p.user.blocked && p.user.confirmed))
          .map((p) => ({ ...p, nome: p.nome || p.user.username })),
      });
    } catch (e) {
      setError("Problema ao tentar carregar dados inicial da aplicação");
    }
    setIsLoading(false);
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <InitContext.Provider value={{ ...data, isLoading }}>
      {children}
    </InitContext.Provider>
  );
}
