import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

export function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Direitos autorais © "}
      <Link color="inherit" href="https://material-ui.com/">
        Casa da Esperança de Cubatão
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
