import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";

import { usePessoa } from "../Pessoa/PessoaContext";

export const DesligamentoMessage = () => {
  const { pessoa } = usePessoa();
  return (
    pessoa.id &&
    pessoa.desligada && (
      <>
        <Grid item xs={12}>
          <Alert severity="info">
            Paciente com cadastro <b>desligado</b>, para realizar novos
            agendamentos e(ou) atendimentos, favor <a href="/desligamento">reativá-lo</a>
          </Alert>
        </Grid>
      </>
    )
  );
};
