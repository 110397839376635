import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import { Login } from "../Login/Login";
import { AcessoPaciente } from "../AcessoPaciente/AcessoPaciente";
import { Pessoa } from "../Pessoa/Pessoa";
import { PessoaAtendimento } from "../PessoaAtendimento/PessoaAtendimento";
import { PlanoTerapeutico } from "../PessoaAtendimento/PlanoTerapeutico";
import { Frequencia } from "../Frequencia/Frequencia";
import { PessoaAgendamento } from "../PessoaAgendamento/PessoaAgendamento";
import { Dashboard } from "../Dashboard/Dashboard";

import { PrivateRoute } from "./PrivateRoute";
import { NotFound } from "./NotFound";
import { PessoaDesligamento } from "../PessoaDesligamento/PessoaDesligamento";

export const Router = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/prontuario-eletronico" element={<AcessoPaciente />} />
      <Route
        path="/paciente"
        element={<PrivateRoute component={<Pessoa />} />}
      />
      <Route
        path="/novo-cadastro"
        element={<PrivateRoute component={<Pessoa />} />}
      />
      <Route
        path="/plano-terapeutico"
        element={<PrivateRoute component={<PlanoTerapeutico />} />}
      />
      <Route
        path="/atendimento"
        element={<PrivateRoute component={<PessoaAtendimento />} />}
      />
      <Route
        path="/atendimento/:id"
        element={<PrivateRoute component={<PessoaAtendimento />} />}
      />
      <Route
        path="/avaliacao/social"
        element={
          <PrivateRoute
            component={<PessoaAtendimento setorName="AssistenciaSocial" />}
          />
        }
      />
      <Route
        path="/avaliacao/terapia-ocupacional"
        element={
          <PrivateRoute
            component={<PessoaAtendimento setorName="TerapiaOcupacional" />}
          />
        }
      />
      <Route
        path="/entrevistas/fonoaudiologo"
        element={
          <PrivateRoute
            component={<PessoaAtendimento setorName="Fonoaudiologia" />}
          />
        }
      />
      <Route
        path="/frequencia"
        element={<PrivateRoute component={<Frequencia />} />}
      />
      <Route
        path="/agendamento"
        element={<PrivateRoute component={<PessoaAgendamento />} />}
      />
      <Route
        path="/desligamento"
        element={<PrivateRoute component={<PessoaDesligamento />} />}
      />
      <Route path="/" element={<PrivateRoute component={<Dashboard />} />} />

      {/* TODO implement proper router */}
      <Route path="*" element={<PrivateRoute component={<NotFound />} />} />
    </Routes>
  </BrowserRouter>
);
