import React, { useState } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import FaceIcon from "@material-ui/icons/Face";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import { usePessoa, PessoaDto } from "../Pessoa/PessoaContext";

import { PessoaBusca } from "../PessoaBusca/PessoaBusca";

export const PessoaBuscaDialog = () => {
  const { pessoa, setPessoa } = usePessoa();
  const [pessoaBuscaDialog, setPessoaBuscaDialog] = useState(false);
  const [finalizarDialog, setFinalizarDialog] = useState(false);
  const [buttonRef, setButtonRef] = useState(null);

  const closeBuscaDialog = () => setPessoaBuscaDialog(false);
  const closeFinalizarDialog = () => setFinalizarDialog(false);
  const finalizar = () => {
    setPessoa(PessoaDto);
    setFinalizarDialog(false);
  };

  const closeMenu = () => setButtonRef(null);

  const onClick = (e) => {
    if (pessoa.id) return setButtonRef(e.currentTarget);
    setPessoaBuscaDialog(true);
  };

  return (
    <Box m={1} display="flex" justifyContent="space-between" className="no-print">
      <Button
        color="primary"
        ref={buttonRef}
        startIcon={<FaceIcon />}
        onClick={onClick}
        title="Paciente em atendimento"
      >
        {pessoa.nome ? pessoa.nome : "Nenhum paciente em atendimento"}
      </Button>

      <Menu
        anchorEl={buttonRef}
        keepMounted
        open={!!buttonRef}
        onClose={closeMenu}
      >
        <MenuItem component={Link} to="/paciente" onClick={closeMenu}>
          Cadastro
        </MenuItem>
        <MenuItem component={Link} to="/agendamento" onClick={closeMenu}>
          Agendamento
        </MenuItem>
        <MenuItem
          onClick={() => {
            setFinalizarDialog(true);
            closeMenu();
          }}
        >
          Finalizar
        </MenuItem>
      </Menu>

      <Dialog open={finalizarDialog} onClose={closeFinalizarDialog}>
        <DialogTitle>Finalizar Atendimento</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deseja realmente finalizar o atendimento a {pessoa.nome}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={finalizar} color="primary">
            Sim
          </Button>
          <Button onClick={closeFinalizarDialog} autoFocus>
            Não
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={pessoaBuscaDialog}
        onClose={closeBuscaDialog}
        fullWidth
        maxWidth="md"
      >
        <PessoaBusca onSelect={closeBuscaDialog} onCancel={closeBuscaDialog} />
      </Dialog>
    </Box>
  );
};
