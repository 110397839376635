import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import IconButton from "@mui/material/IconButton";
import { useAuth } from "../Auth/AuthContext";

export function Logout() {
  const [open, setOpen] = React.useState(false);
  const { logout } = useAuth();

  const handleClose = () => {
    setOpen(false);
  };

  const exit = () => {
    logout();
    setOpen(false);
  };

  return (
    <>
      <IconButton
        title="Sair da aplicação"
        color="inherit"
        onClick={() => setOpen(true)}
      >
        <ExitToAppIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Sair da Aplicação
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Deseja realmente fecha a aplicação e retornar à tela de login?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={exit} color="primary">
            Sim
          </Button>
          <Button onClick={handleClose} autoFocus>
            Não
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
