import React, { useState } from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { useLocation, useNavigate } from "react-router-dom";

import { usePessoa } from "../Pessoa/PessoaContext";
import { useMessage } from "../Message/MessageContext";
import { LoadingButton } from "../../components/Loading";

import { Resultado } from "./Resultado";

const searchOptions = {
  Prontuário: "id",
  Nome: "nome_contains",
  CPF: "cpf",
  RG: "rg",
  "Prontuário Físico": "numeroProntuario",
};

const searchKeys = Object.keys(searchOptions);

export const PessoaBusca = ({ onSelect, onCancel }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setSuccess } = useMessage();
  const { searchPessoa, setPessoa, isLoading } = usePessoa();
  const [busca, setBusca] = useState(searchKeys[0]);
  const [keyword, setKeyword] = useState("");
  const [searchResult, setSearchResult] = useState(undefined);

  const search = async (e) => {
    e.preventDefault();
    var result = await searchPessoa({ [searchOptions[busca]]: keyword });
    setSearchResult(result);
  };

  const handleSelect = (p) => {
    if (location.pathname === "/novo-cadastro") {
      navigate("/paciente");
    }
    setPessoa(p);
    setSuccess(`${p.nome} em atendimento`);
    onSelect(p);
  };

  return (
    <form onSubmit={search}>
      <DialogTitle>Buscar Paciente</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} sx={{ marginTop: 1 }}>
          <Grid item xs={12} md={4}>
            <Autocomplete
              options={searchKeys}
              fullWidth
              onInputChange={(_, value) => setBusca(value)}
              inputValue={busca}
              defaultValue={busca}
              renderInput={(params) => (
                <TextField {...params} label="Buscar por" variant="outlined" />
              )}
            />
          </Grid>
          <Grid item xs={12} md={8}>
            <TextField
              variant="outlined"
              required
              onChange={(e) => setKeyword(e.target.value)}
              fullWidth
              label={busca}
              value={keyword}
              autoFocus
            />
          </Grid>
        </Grid>
        <Resultado searchResult={searchResult} onSelect={handleSelect} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancelar</Button>
        <LoadingButton
          loading={isLoading}
          type="submit"
          variant="outlined"
          color="primary"
        >
          Buscar
        </LoadingButton>
      </DialogActions>
    </form>
  );
};
