import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AssignmentIcon from "@material-ui/icons/Assignment";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import CloseIcon from "@material-ui/icons/Close";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import AccessibleIcon from "@material-ui/icons/Accessible";
import HearingIcon from "@material-ui/icons/Hearing";
import RotateRightIcon from "@material-ui/icons/RotateRight";
import { Link } from "react-router-dom";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import FaceIcon from "@material-ui/icons/Face";
import TodayIcon from "@material-ui/icons/Today";
import ListAltIcon from '@mui/icons-material/ListAlt';

export const Menu = () => (
  <div>
    <Divider />
    <List>
      <ListItem button component={Link} to="/">
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Início" />
      </ListItem>
      <ListItem button component={Link} to="/novo-cadastro">
        <ListItemIcon>
          <PersonAddIcon />
        </ListItemIcon>
        <ListItemText primary="Novo Cadastro" />
      </ListItem>
    </List>
    <Divider />
    <List>
      <ListSubheader inset>Em Atendimento</ListSubheader>
      <ListItem button component={Link} to="/paciente">
        <ListItemIcon>
          <FaceIcon />
        </ListItemIcon>
        <ListItemText primary="Paciente" />
      </ListItem>
      <ListItem button component={Link} to="/plano-terapeutico">
        <ListItemIcon>
          <ListAltIcon />
        </ListItemIcon>
        <ListItemText primary="Plano Terapêutico" />
      </ListItem>
      <ListItem button component={Link} to="/agendamento">
        <ListItemIcon>
          <TodayIcon />
        </ListItemIcon>
        <ListItemText primary="Agendamento" />
      </ListItem>
      <ListItem button component={Link} to="/frequencia">
        <ListItemIcon>
          <PlaylistAddCheckIcon />
        </ListItemIcon>
        <ListItemText primary="Frequência" />
      </ListItem>
      <ListItem button component={Link} to="/atendimento">
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Atendimento" />
      </ListItem>
      <ListItem button component={Link} to="/desligamento">
        <ListItemIcon>
          <CloseIcon />
        </ListItemIcon>
        <ListItemText primary="Desligamento" />
      </ListItem>
    </List>
    <Divider />
    <List>
      <ListSubheader inset>Avaliações</ListSubheader>
      <ListItem button component={Link} to="/avaliacao/social">
        <ListItemIcon>
          <AccessibilityIcon />
        </ListItemIcon>
        <ListItemText primary="Social" />
      </ListItem>
      <ListItem button component={Link} to="/avaliacao/terapia-ocupacional">
        <ListItemIcon>
          <AccessibleIcon />
        </ListItemIcon>
        <ListItemText primary="Terapia Ocupacional" />
      </ListItem>
    </List>
    <Divider />
    <List>
      <ListSubheader inset>Termos</ListSubheader>
      <ListItem button component={Link} to="/termos/responsabilidade">
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="Responsabilidade" />
      </ListItem>
      <ListItem button component={Link} to="/termos/retomada">
        <ListItemIcon>
          <RotateRightIcon />
        </ListItemIcon>
        <ListItemText primary="Retomada" />
      </ListItem>
    </List>
    <Divider />
    <List>
      <ListSubheader inset>Entrevistas</ListSubheader>
      <ListItem button component={Link} to="/entrevistas/fonoaudiologo">
        <ListItemIcon>
          <HearingIcon />
        </ListItemIcon>
        <ListItemText primary="Fonoaudiologo" />
      </ListItem>
    </List>
  </div>
);
