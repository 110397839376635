import startOfWeek from "date-fns/startOfWeek";
import nextMonday from "date-fns/nextMonday";
import isWeekend from "date-fns/isWeekend";

export function beginAndEndOfNextWeek() {
  const today = new Date();

  const monday = isWeekend(today)
    ? nextMonday(today)
    : nextMonday(startOfWeek(today));

  const friday = new Date(monday);
  friday.setDate(friday.getDate() + 4);

  return [monday, friday];
}
