export const PessoaOdontologiaDto = {
  cuidadosMedicos: undefined,
  remedioDiario: "",
  hospitalizadoUltimosAnos: undefined,
  alergiaMedicamento: "",
  problemaCardiaco: undefined,
  pressaoAlta: undefined,
  febreReumatica: undefined,
  problemasSanguineos: "",
  problemasRespiratorios: undefined,
  hepatite: "",
  diabetes: "",
  doencaContagiosa: "",
  fumante: undefined,
  gengivite: undefined,
  periodontite: undefined,
  dentesAusentes: undefined,
  outros: "",
};
