import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../Auth/AuthContext";
import { InitProvider } from "./InitContext";
import Layout from "../Layout/Layout";

export const PrivateRoute = ({ component, ...props }) => {
  const { authenticated } = useAuth();
  const loginRoute = {
    pathname: "/login",
    state: {
      from: props.location,
    },
  };

  if (!authenticated) {
    return <Navigate to={loginRoute} />;
  }

  return (
    <InitProvider>
      <Layout {...props}>{component}</Layout>
    </InitProvider>
  );
};
