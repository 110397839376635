import { useState, useEffect, createContext, useContext } from "react";
import endOfMonth from "date-fns/endOfMonth";

import { useMessage } from "../Message/MessageContext";
import { useAuth } from "../Auth/AuthContext";
import { Atendimento } from "../../api/api";
import { usePessoa } from "../Pessoa/PessoaContext";

export const useRelatorios = () => {
  const { user } = useAuth();
  const { pessoa } = usePessoa();
  const { setError } = useMessage();
  const [listAgendamento, setListAgendamento] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setListAgendamento(false);
  }, [pessoa.id]);

  const frequenciaMes = async (fromMesAno, toMesAno, setorAtendimento) => {
    if (!pessoa.id) return setListAgendamento([]);

    const fromDateFilter = formatDate(fromMesAno);
    const toDateFilter = endOfMonth(formatDate(toMesAno));

    setIsLoading(true);
    try {
      const { data } = await Atendimento(user.jwt).get({
        pessoa: pessoa.id,
        dataAgendamento_gte: fromDateFilter,
        dataAgendamento_lte: toDateFilter,
        ...(setorAtendimento?.id
          ? { setorAtendimento: setorAtendimento.id }
          : {}),
        _sort: "dataAgendamento:desc",
      });
      setListAgendamento(data);
    } catch (e) {
      setError("Problema ao consultar relatório de frequencia");
    }
    setIsLoading(false);
  };

  return { listAgendamento, frequenciaMes, isLoading };
};

const formatDate = (mesAno) => {
  const arrMesAno = mesAno.split("/");
  return new Date(
    parseInt(arrMesAno[1].length === 2 ? `20${arrMesAno[1]}` : arrMesAno[1]),
    parseInt(arrMesAno[0]) - 1,
    1
  );
};
