import React, { useState } from "react";
import Avatar from "@mui/material/Avatar";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import PlaylistAddCheckIcon from "@material-ui/icons/PlaylistAddCheck";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import SearchIcon from "@material-ui/icons/Search";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import FindInPageIcon from "@mui/icons-material/FindInPage";

import AgendamentoList from "../Agendamento/List";
import StyledRoot, { classes } from "../../utils/pageStyles";
import { PessoaEmAtendimento } from "../Pessoa/PessoaEmAtendimento";
import { useInit } from "../Router/InitContext";
import { TextFieldMonthYear } from "../../components/MaskedTextField";
import { LoadingButton } from "../../components/Loading";
import { useRelatorios } from "../PessoaAgendamento/useRelatorios";
import { usePessoa } from "../Pessoa/PessoaContext";

export function Frequencia() {
  const navigate = useNavigate();
  const { setoresAtendimento } = useInit();
  const { pessoa } = usePessoa();
  const [setorAtendimento, setSetorAtendimento] = useState({});
  const { listAgendamento, frequenciaMes, isLoading } = useRelatorios();
  const [fromMesAno, setFromMesAno] = useState(format(new Date(), "MM/yyyy"));
  const [toMesAno, setToMesAno] = useState(format(new Date(), "MM/yyyy"));

  const onSubmit = async (e) => {
    e.preventDefault();
    await frequenciaMes(fromMesAno, toMesAno, setorAtendimento);
  };

  return (
    <Container component="main" maxWidth="md">
      <StyledRoot className={classes.paper}>
        <Avatar className={classes.avatar}>
          <PlaylistAddCheckIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Frequência
        </Typography>
        <form className={classes.form} onSubmit={onSubmit}>
          <Grid container spacing={2}>
            <PessoaEmAtendimento />
            {pessoa.id && (
              <>
                <Grid item xs={12}>
                  <Autocomplete
                    options={setoresAtendimento}
                    value={setorAtendimento}
                    getOptionLabel={(option) => option.nome || "Todos"}
                    onChange={(e, setorAtendimento) =>
                      setSetorAtendimento(setorAtendimento)
                    }
                    fullWidth
                    renderInput={(params) => (
                      <TextField {...params} label="Setor" variant="outlined" />
                    )}
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <TextFieldMonthYear
                    variant="outlined"
                    required
                    fullWidth
                    value={fromMesAno}
                    onChange={(e) => setFromMesAno(e.target.value)}
                    label="Do: Mês/Ano"
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <TextFieldMonthYear
                    variant="outlined"
                    required
                    fullWidth
                    value={toMesAno}
                    onChange={(e) => setToMesAno(e.target.value)}
                    label="Até: Mês/Ano"
                  />
                </Grid>
                <Grid item xs={6} sm={4}>
                  <LoadingButton
                    loading={isLoading}
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    size="large"
                    className={classes.button}
                    startIcon={<SearchIcon />}
                  >
                    Visualizar
                  </LoadingButton>
                </Grid>
                <Grid item xs={12}>
                  {listAgendamento.length === 0 ? (
                    <Alert severity="warning">
                      Nenhum atendimento realizado
                    </Alert>
                  ) : (
                    <AgendamentoList
                      agendamentos={listAgendamento}
                      actions={(a) => (
                        <Button
                          startIcon={<FindInPageIcon />}
                          title="Visualizar atendimento"
                          onClick={() => navigate(`/atendimento/${a.id}`)}
                        >
                          Visualizar
                        </Button>
                      )}
                    />
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </form>
      </StyledRoot>
    </Container>
  );
}
