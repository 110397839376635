import {
  add,
  isBefore,
  startOfDay,
  nextDay,
  startOfWeek,
  format,
  addDays,
} from "date-fns";
import { ptBR } from "date-fns/locale";

export const ListRecorrencia = {
  naoRepete: "Não se repete",
  diaria: "Diária",
  semanal: "Semanal",
  quinzenal: "Quinzenal",
  mensal: "Mensal",
  bimestral: "Bimestral",
  trimestral: "Trimestral",
};

export const ptWeekdays = (function () {
  const firstDOW = startOfWeek(new Date());
  return Array.from(Array(7)).map((e, i) =>
    format(addDays(firstDOW, i), "EEEEEE", { locale: ptBR })
  );
})();

export const ptWeekDayFromDate = (date) =>
  format(date, "EEEEEE", { locale: ptBR });

// Calcula numero e data de agendamentos que devem ser criados
export const calcAgendamentoRepeticoes = ({
  dataAgendamento,
  selectedDays,
  repeticoes,
  recorrencia,
}) => {
  let result = [dataAgendamento];
  let isSemanalOrQuinzenal =
    recorrencia === ListRecorrencia.semanal ||
    recorrencia === ListRecorrencia.quinzenal;

  const hasMoreRepeticoes = () =>
    Number.isInteger(repeticoes)
      ? result.length < repeticoes + 1
      : isBefore(result.slice(-1)[0], startOfDay(repeticoes));

  if (
    !dataAgendamento ||
    !repeticoes ||
    !recorrencia ||
    recorrencia === ListRecorrencia.naoRepete
  )
    return result;

  if (isSemanalOrQuinzenal) {
    var previousDate = dataAgendamento;
    let currentDay = ptWeekDayFromDate(previousDate);

    while (hasMoreRepeticoes()) {
      currentDay = selectedDays[selectedDays.indexOf(currentDay) + 1];

      if (!currentDay) {
        currentDay = selectedDays[0];

        if (recorrencia === ListRecorrencia.quinzenal) {
          previousDate = add(previousDate, { weeks: 1 });
        }
      }

      previousDate = nextDay(previousDate, ptWeekdays.indexOf(currentDay));
      result.push(previousDate);
    }
    return result;
  }

  while (hasMoreRepeticoes()) {
    result.push(
      add(dataAgendamento, {
        [mapper[recorrencia].key]: result.length * mapper[recorrencia].factor,
      })
    );
  }
  return result;
};

const mapper = {
  [ListRecorrencia.diaria]: { key: "days", factor: 1 },
  [ListRecorrencia.mensal]: { key: "months", factor: 1 },
  [ListRecorrencia.bimestral]: { key: "months", factor: 2 },
  [ListRecorrencia.trimestral]: { key: "months", factor: 3 },
};
