import React from "react";
import TextField from "@mui/material/TextField";
import { IMaskInput } from "react-imask";

const InputMask = React.forwardRef(({ onChange, name, ...other }, ref) => (
  <IMaskInput
    {...other}
    inputRef={ref}
    onAccept={(value) => onChange({ target: { name, value } })}
    overwrite
  />
));

const TextFieldMask = ({ inputProps, mask, ...props }) => (
  <TextField
    {...props}
    InputProps={{
      inputComponent: InputMask,
      inputProps: {
        mask,
        ...inputProps,
      },
    }}
  />
);

export const TextFieldCPF = (props) => (
  <TextFieldMask {...props} mask="000.000.000-00" />
);

export const TextFieldTelefone = (props) => (
  <TextFieldMask {...props} mask="(00)0000-00000" />
);

export const TextFieldMonthYear = (props) => (
  <TextFieldMask {...props} mask="00/0000" />
);

//TODO adicionar label horas/dias
export const TextFieldHourDay = (props) => (
  <TextFieldMask {...props} mask="00/00" />
);

export const TextFieldSUS = (props) => (
  <TextFieldMask {...props} mask="000.0000.0000.0000" />
);

export const TextFieldCEP = (props) => (
  <TextFieldMask {...props} mask="00000000" />
);
