import React, { useRef, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, Button, ButtonGroup } from "@mui/material";
import mapaDentes from "./mapa-dentes.png";
import pencilIcon from "./pencil-icon.png";
import clearIcon from "./clear-icon.png";
import DeleteIcon from "@mui/icons-material/Delete";
import CreateIcon from "@mui/icons-material/Create";
import CleaningServicesIcon from "@mui/icons-material/CleaningServices";
import { usePessoaAgendamentoAnexos } from "../../PessoaAgendamento/PessoaAgendamentoAnexosContext";
import { ApiUrl } from "../../../api/api";

export const Canvas = ({ pessoa }) => {
  const canvasRef = useRef(null);
  const [isActing, setIsActing] = useState(false);
  const [isErasing, setIsErasing] = useState(false);
  const [ctx, setCtx] = useState(null);
  const { setAnexos, anexos } = usePessoaAgendamentoAnexos();
  const anexoUrl = anexos.mapaDentes?.anexo?.url;

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    setCtx(context);
  }, [pessoa?.id]);

  useEffect(() => {
    if (ctx && anexoUrl) loadDrawing();
  }, [ctx, anexoUrl]);

  const startDrawing = (e) => {
    setIsActing(true);
    ctx.beginPath();
    ctx.moveTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
  };

  const draw = (e) => {
    if (!isActing) return;

    if (isErasing) {
      eraseAtPosition(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
      return;
    }

    ctx.strokeStyle = "red";
    ctx.lineTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY);
    ctx.stroke();
  };

  const stopDrawing = () => {
    if (!isActing) return;

    setIsActing(false);
    ctx.closePath();
    saveDrawing();
  };

  const eraseAtPosition = (x, y) => {
    ctx.clearRect(x - 10, y - 10, 20, 20); // Size of the erase area
  };

  const eraseAll = () => {
    const canvas = canvasRef.current;
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    setIsErasing(false);
  };

  const saveDrawing = () => {
    const canvas = canvasRef.current;
    canvas.toBlob((blob) => {
      setAnexos.mapaDentes(blob);
    });
  };

  const loadDrawing = () => {
    const img = new Image();
    img.src = ApiUrl + anexos.mapaDentes.anexo.url;

    img.onload = () => {
      const canvas = canvasRef.current;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
    };
  };

  return (
    <>
      <Container
        sx={{
          cursor: `url(${isErasing ? clearIcon : pencilIcon}) 0 10, auto`,
        }}
      >
        <img
          style={{ width: 800, height: 600 }}
          src={mapaDentes}
          alt="mapa dos dentes"
        />
        <canvas
          ref={canvasRef}
          width={800}
          height={600}
          onMouseDown={startDrawing}
          onMouseMove={draw}
          onMouseUp={stopDrawing}
          onMouseLeave={stopDrawing}
          onMouseMoveCapture={draw}
        />
      </Container>
      <Box mb={1}>
        <ButtonGroup size="small" aria-label="Small button group">
          <Button
            variant={!isErasing ? "contained" : "outlined"}
            endIcon={<CreateIcon />}
            onClick={() => setIsErasing(false)}
          >
            Lápis
          </Button>
          <Button
            variant={isErasing ? "contained" : "outlined"}
            endIcon={<CleaningServicesIcon />}
            onClick={() => setIsErasing(true)}
          >
            Borracha
          </Button>
          <Button onClick={eraseAll} endIcon={<DeleteIcon />}>
            Apagar tudo
          </Button>
        </ButtonGroup>
      </Box>
    </>
  );
};

const Container = styled("div")({
  overflow: "auto",
  position: "relative",
  height: 600,
  width: 800,
  "& canvas, & img": {
    position: "absolute",
    top: 0,
    left: 0,
  },
});
