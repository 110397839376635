import { useEffect, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import DatePicker from "@mui/lab/DatePicker";
import DoneIcon from "@mui/icons-material/Done";
import EventIcon from "@mui/icons-material/Event";
import { format } from "date-fns";

import { usePessoaAgendamento } from "./PessoaAgendamentoContext";
import {
  calcAgendamentoRepeticoes,
  ListRecorrencia,
  ptWeekDayFromDate,
  ptWeekdays,
} from "./Repeticoes";

export const Recorrencia = () => {
  const {
    agendamento: { dataAgendamento, dtRecorrencias },
    setDtAgendamentos,
  } = usePessoaAgendamento();

  const [recorrencia, setRecorrencia] = useState(ListRecorrencia.naoRepete);
  const [termino, setTermino] = useState(TerminaEm.numeroRepeticoes);
  const [selectedDays, setSelectedDays] = useState([]);
  const [repeticoes, setRepeticoes] = useState();

  useEffect(() => {
    try {
      const day = ptWeekDayFromDate(dataAgendamento);
      if (!selectedDays.includes(day)) setSelectedDays([...selectedDays, day]);
    } catch (e) {}
  }, [dataAgendamento]);

  useEffect(() => {
    if (recorrencia === ListRecorrencia.naoRepete) return setDtAgendamentos([]);

    if (!(dataAgendamento instanceof Date) || !repeticoes || !recorrencia)
      return setDtAgendamentos([]);

    setDtAgendamentos(
      calcAgendamentoRepeticoes({
        dataAgendamento,
        selectedDays,
        repeticoes:
          repeticoes instanceof Date ? repeticoes : Number.parseInt(repeticoes),
        recorrencia,
      })
    );
  }, [dataAgendamento, selectedDays, repeticoes, recorrencia]);

  return (
    <>
      <Grid item xs={12} md={3}>
        <Autocomplete
          options={Object.values(ListRecorrencia)}
          value={recorrencia}
          getOptionLabel={(r) => r || ""}
          onChange={(e, r) => setRecorrencia(r)}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              label="Recorrência"
              variant="outlined"
              required
            />
          )}
        />
      </Grid>
      {(recorrencia === ListRecorrencia.semanal ||
        recorrencia === ListRecorrencia.quinzenal) && (
        <Grid
          item
          xs={12}
          md={9}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Stack direction="row" spacing={1}>
            {ptWeekdays.map((d) => {
              const selected = selectedDays.includes(d);

              const clickEvent = ptWeekDayFromDate(dataAgendamento) !== d && {
                onClick: () =>
                  setSelectedDays(
                    selected
                      ? selectedDays.filter((i) => i !== d)
                      : [...selectedDays, d]
                  ),
              };

              return (
                <Chip
                  {...clickEvent}
                  label={d}
                  key={d}
                  color="primary"
                  variant={selected ? "filled" : "outlined"}
                  icon={
                    !clickEvent ? (
                      <EventIcon />
                    ) : selected ? (
                      <DoneIcon />
                    ) : undefined
                  }
                />
              );
            })}
          </Stack>
        </Grid>
      )}
      {recorrencia !== ListRecorrencia.naoRepete && (
        <>
          <Grid item xs={12}>
            <FormControl>
              <FormLabel id="radio-group">Termina</FormLabel>
              <RadioGroup
                row
                aria-labelledby="radio-group"
                name="name-radio-group"
                value={termino}
                onChange={(_, t) => setTermino(t)}
              >
                <FormControlLabel
                  value={TerminaEm.numeroRepeticoes}
                  control={<Radio />}
                  label="após número especifico de repetições"
                />
                <FormControlLabel
                  value={TerminaEm.dataEspecifica}
                  control={<Radio />}
                  label="em data específica"
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          {termino && (
            <Grid item xs={12} md={3}>
              {termino === TerminaEm.dataEspecifica ? (
                <DatePicker
                  autoOk
                  fullWidth
                  autoFocus
                  required
                  disablePast
                  value={repeticoes}
                  onChange={setRepeticoes}
                  inputFormat="dd/MM/yyyy"
                  inputVariant="outlined"
                  label="Data de término"
                  renderInput={(p) => <TextField {...p} />}
                />
              ) : (
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  onChange={(e) => {
                    const vl = e.target.value;
                    if (!vl || parseInt(vl) >= 1) setRepeticoes(vl);
                  }}
                  value={repeticoes}
                  type="number"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  label="Nº repetições"
                  autoFocus
                />
              )}
            </Grid>
          )}
        </>
      )}

      {dtRecorrencias.length > 0 && (
        <Grid item xs={12}>
          <Alert severity="info">
            Recorrências para os dias:{" "}
            <strong>
              {dtRecorrencias
                .slice(0, MAX_SHOW_AGENDAMENTO)
                .map((d) => format(d, "dd/MM"))
                .join("; ")}
            </strong>{" "}
            {dtRecorrencias.length > MAX_SHOW_AGENDAMENTO &&
              ` e mais ${dtRecorrencias.length - MAX_SHOW_AGENDAMENTO} datas `}
            no mesmo horário
          </Alert>
        </Grid>
      )}
    </>
  );
};

const TerminaEm = {
  dataEspecifica: "dataEspecifica",
  numeroRepeticoes: "numeroRepeticoes",
};

const MAX_SHOW_AGENDAMENTO = 10;
