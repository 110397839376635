import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import { useInit } from "../Router/InitContext";

export const ProfissionalSetor = ({
  onChangeSetor,
  onChangeProfissional,
  setorAtendimento = {},
  profissional = {},
  gridSetorProps = {},
  gridProfissionalProps = {},
}) => {
  const { setoresAtendimento, profissionais, isLoading } = useInit();
  const [profissionaisSetor, setProfissionaisSetor] = useState([]);

  useEffect(() => {
    setProfissionaisSetor(
      profissionais.filter((p) =>
        p.setor_atendimentos.some((s) => s.id === (setorAtendimento || {}).id)
      )
    );
  }, [setorAtendimento, isLoading]);

  return (
    <>
      <Grid item {...gridSetorProps}>
        <Autocomplete
          options={setoresAtendimento}
          value={setorAtendimento}
          getOptionLabel={(option) => option.nome || ""}
          onChange={onChangeSetor}
          fullWidth
          renderInput={(params) => (
            <TextField {...params} label="Setor" variant="outlined" required />
          )}
        />
      </Grid>
      <Grid item {...gridProfissionalProps}>
        <Autocomplete
          options={profissionaisSetor}
          value={profissional}
          getOptionLabel={(option) => option.nome || ""}
          onChange={onChangeProfissional}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              label="Profissional"
              variant="outlined"
              required
            />
          )}
          noOptionsText="Nenhum profissional disponível"
        />
      </Grid>
    </>
  );
};
