import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import ListAltIcon from "@mui/icons-material/ListAlt";
import LinearProgress from "@mui/material/LinearProgress";
import Alert from "@mui/material/Alert";
import { format } from "date-fns";

import StyledRoot, { classes } from "../../utils/pageStyles";
import { PessoaEmAtendimento } from "../Pessoa/PessoaEmAtendimento";
import { usePessoa } from "../Pessoa/PessoaContext";
import { useAuth } from "../Auth/AuthContext";
import { Atendimento } from "../../api/api";

const PLANO_TERAPEUTICO_ID = 21;

export function PlanoTerapeutico() {
  const { pessoa } = usePessoa();
  const { user } = useAuth();
  const [listItem, setListItem] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const load = async () => {
    if (!pessoa.id) return setIsLoading(false);

    setIsLoading(true);
    const { data } = await Atendimento(user.jwt).get({
      pessoa: pessoa.id,
      dataAtendimento_null: false,
      "setorAtendimento.id": PLANO_TERAPEUTICO_ID,
      _sort: "dataAtendimento:desc",
    });

    setListItem(data);
    setIsLoading(false);
  };

  useEffect(() => {
    load();
  }, [pessoa.id]);

  return (
    <Container component="main" maxWidth="md">
      <StyledRoot className={classes.paper}>
        <Avatar className={classes.avatar}>
          <ListAltIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Plano Terapêutico
        </Typography>

        <div className={classes.form}>
          <Grid container spacing={2}>
            {isLoading && (
              <Grid item xs={12}>
                <LinearProgress />
              </Grid>
            )}
            <PessoaEmAtendimento />

            {pessoa.id && !listItem.length && !isLoading && (
              <Grid item xs={12}>
                <Alert severity="info">
                  Nenhum plano de terapêutico cadastrado para esse paciente
                </Alert>
              </Grid>
            )}

            {pessoa.id && (
              <>
                {listItem.map((item) => (
                  <>
                    <Grid item xs={12} md={4}>
                      <Typography variant="subtitle2">
                        Data de atendimento:
                      </Typography>
                      <Typography>
                        {format(
                          new Date(item.dataAtendimento),
                          "dd/MM/yyyy H:mm"
                        )}
                      </Typography>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <Typography variant="subtitle2">Setor:</Typography>
                      <Typography>{item.setorAtendimento.nome}</Typography>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      {item.profissional?.nome && (
                        <>
                          <Typography variant="subtitle2">
                            Profissional:
                          </Typography>
                          <Typography>{item.profissional?.nome}</Typography>
                        </>
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="subtitle2">
                        Detalhamento do atendimento:
                      </Typography>
                      <Typography
                        sx={{ height: 300, overflowY: "auto" }}
                        dangerouslySetInnerHTML={{ __html: item.descricao }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </>
                ))}
              </>
            )}
          </Grid>
        </div>
      </StyledRoot>
    </Container>
  );
}
