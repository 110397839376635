import React from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Alert from "@mui/material/Alert";
import { format } from "date-fns";
import { Chip, Tooltip } from "@mui/material";

export const Resultado = ({ searchResult, onSelect }) => {
  if (!searchResult) return null;
  if (searchResult.length === 0)
    return (
      <Box marginTop={2}>
        <Alert severity="warning">Nenhum paciente encontrado</Alert>
      </Box>
    );

  return (
    <Container>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell>Pront.</TableCell>
            <TableCell>Nome</TableCell>
            <TableCell>CPF</TableCell>
            <TableCell>RG</TableCell>
            <TableCell>Nascimento</TableCell>
            <TableCell>Pront. Físico</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {searchResult.map((pessoa) => (
            <TableRow
              onClick={() => onSelect(pessoa)}
              hover
              tabIndex={-1}
              key={pessoa.id}
              sx={{ cursor: "pointer" }}
            >
              <TableCell>{pessoa.id}</TableCell>
              <TableCell>{pessoa.nome}</TableCell>
              <TableCell>{pessoa.CPF}</TableCell>
              <TableCell>{pessoa.RG}</TableCell>
              <TableCell>
                {pessoa.nascimento &&
                  format(new Date(pessoa.nascimento), "dd/MM/yyyy")}
              </TableCell>
              <TableCell>
                {pessoa.desligada ? (
                  <Tooltip title="Paciente com cadastro desligado">
                    <Chip label="Desligado" color="error" />
                  </Tooltip>
                ) : (
                  pessoa.numeroProntuario
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Container>
  );
};

const Container = styled(TableContainer)(({ theme }) => ({
  paddingTop: theme.spacing(2),
  maxHeight: 600,
}));
