import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

const laudoReceitaSetor = [5, 6, 7, 12, 14];

export const LaudoReceita = ({
  agendamento,
  laudo,
  receita,
  onLaudoChange,
  onReceitaChange,
}) => {
  if (!laudoReceitaSetor.find((id) => id === agendamento.setorAtendimento?.id))
    return false;

  return (
    <>
      <Grid item xs={12}>
        <Typography fontWeight="bold">Laudo Médico</Typography>
        <TextField
          fullWidth
          onChange={(e) => onLaudoChange(e.target.value)}
          value={laudo}
          placeholder="Detalhamento do laudo médico"
          multiline
          rows={4}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography fontWeight="bold">Receita Médica</Typography>
        <TextField
          fullWidth
          onChange={(e) => onReceitaChange(e.target.value)}
          value={receita}
          placeholder="Detalhamento do receita médica"
          multiline
          rows={4}
        />
      </Grid>
    </>
  );
};
