import { PessoaOdontologiaDto } from "./PessoaOdontologia.dto";
import { PessoaTerapiaOcupacionalDto } from "./PessoaTerapiaOcupacional.dto";
import { PessoaAssistenciaSocialDto } from "./PessoaAssistenciaSocial.dto";
import { PessoaFonoaudiologiaDto } from "./PessoaFonoaudiologia.dto";

export const PessoaDto = {
  id: "",
  nome: "",
  nascimento: null,
  RG: "",
  CPF: "",
  nomePai: "",
  nomeMae: "",
  responsavel: "",
  endereco: "",
  numeroEndereco: "",
  telefone: "",
  celular: "",
  telefoneRecado: "",
  diagnostico: "",
  CID: "",
  CEP: "",
  numeroSUSResponsavel: "",
  numeroSusReabilitando: "",
  numeroProntuario: "",
  bairro: {},
  sexoMasculino: true,
  planoSaude: false,
  odontologia: PessoaOdontologiaDto,
  fonoaudiologia: PessoaFonoaudiologiaDto,
  assistenciaSocial: PessoaAssistenciaSocialDto,
  terapiaOcupacional: PessoaTerapiaOcupacionalDto,
  horarioEscolar: "",
};

export const PessoaHorarioEcolarDto = [
  'Matutino',
  'Vespertino',
  'Noturno',
  'Não estuda'
]