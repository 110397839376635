import React, { useEffect, useState } from "react";
import Container from "@mui/material/Container";
import StyledRoot, { classes } from "../../utils/pageStyles";
import Avatar from "@mui/material/Avatar";
import FeaturedPlayListIcon from "@mui/icons-material/FeaturedPlayList";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import MUIRichTextEditor from "mui-rte";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import CheckIcon from "@mui/icons-material/Check";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Tooltip from "@mui/material/Tooltip";
import { EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import { convertToRaw } from "draft-js";

import { ProfissionalSetor } from "../ProfissionalSetor/ProfissionalSetor";
import { PessoaEmAtendimento } from "../Pessoa/PessoaEmAtendimento";
import { usePessoa } from "../Pessoa/PessoaContext";
import { useDesligamento } from "./DesligamentoContext";
import { LoadingButton } from "@mui/lab";
import { Historico } from "./Historico";

export function PessoaDesligamento() {
  const { pessoa } = usePessoa();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [setorAtendimento, setSetorAtendimento] = useState(null);
  const [profissional, setProfissional] = useState(null);
  const [confirmationDialog, setConfirmationDialog] = useState(false);
  const [showHistorico, setShowHistorico] = useState(false);
  const [validationError, setValidationError] = useState(false);
  const {
    desligamentos,
    loadDesligamentos,
    ativo,
    isLoading,
    addDesligamento,
  } = useDesligamento();
  const status = isLoading
    ? ""
    : ativo?.religamento === false
    ? "Religamento"
    : "Desligamento";

  const statusVerb = ativo?.religamento === false ? "religar" : "desligar";

  useEffect(() => {
    if (!desligamentos.length && pessoa.id) loadDesligamentos();
  }, [pessoa.id]);

  useEffect(() => {
    setProfissional(null);
    setSetorAtendimento(null);
    setEditorState(EditorState.createEmpty());
  }, [ativo?.religamento]);

  const performAction = () => {
    addDesligamento({
      profissional: profissional.id,
      religamento: ativo?.religamento === false,
      motivo: stateToHTML(editorState.getCurrentContent()),
    });
    setConfirmationDialog(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    if (isContentEmpty(editorState.getCurrentContent()))
      return setValidationError(true);

    setValidationError(false);
    setConfirmationDialog(true);
  };

  const isContentEmpty = (content) => {
    const rawContent = convertToRaw(content);
    return rawContent.blocks.every((block) => !block.text.trim());
  };

  return (
    <Container component="main" maxWidth="md">
      <StyledRoot className={classes.paper}>
        <Avatar className={classes.avatar}>
          <FeaturedPlayListIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          {status}
        </Typography>
        <form className={classes.form} onSubmit={onSubmit}>
          <Grid container spacing={2}>
            <PessoaEmAtendimento />

            {pessoa.id && (
              <>
                <ProfissionalSetor
                  gridSetorProps={{ xs: 12, md: 6 }}
                  gridProfissionalProps={{ xs: 12, md: 6 }}
                  setorAtendimento={setorAtendimento}
                  onChangeSetor={(e, setorAtendimento) =>
                    setSetorAtendimento(setorAtendimento)
                  }
                  profissional={profissional}
                  onChangeProfissional={(e, profissional) =>
                    setProfissional(profissional)
                  }
                />
                <Grid item xs={12}>
                  <MUIRichTextEditor
                    label={status ? ` Motivo do ${status}` : ""}
                    inlineToolbar={false}
                    editorState={editorState}
                    onChange={setEditorState}
                  />
                </Grid>

                {validationError && (
                  <Grid item xs={12}>
                    <Alert severity="error">
                      Favor informar o motivo do {status}
                    </Alert>
                  </Grid>
                )}

                {!ativo || ativo?.religamento === true ? (
                  <Grid item xs={12}>
                    <Alert severity="info">
                      Atenção: uma vez desligado, agendamentos futuros serão
                      cancelados e novos agendamentos não serão possíveis.
                    </Alert>
                  </Grid>
                ) : null}
                <Grid item xs={6}>
                  <LoadingButton
                    fullWidth
                    variant="contained"
                    size="large"
                    type="submit"
                    className={classes.button}
                    startIcon={<CheckIcon />}
                    loading={isLoading}
                  >
                    {statusVerb} paciente
                  </LoadingButton>
                </Grid>
              </>
            )}

            {desligamentos.length > 0 && (
              <Grid item xs={6}>
                <Tooltip title="Visualizar histórico de desligamentos e religamentos">
                  <Button
                    fullWidth
                    variant="outlined"
                    size="large"
                    onClick={() => setShowHistorico(true)}
                    className={classes.button}
                    startIcon={<ManageSearchIcon />}
                  >
                    Histórico
                  </Button>
                </Tooltip>
              </Grid>
            )}
          </Grid>
        </form>
        <Dialog
          open={confirmationDialog}
          onClose={() => setConfirmationDialog(false)}
          fullWidth
        >
          <DialogTitle>{status}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Deseja realmente <b>{statusVerb}</b> a <b>{pessoa.nome}</b>?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              loading={isLoading}
              onClick={performAction}
              color="primary"
            >
              Sim
            </LoadingButton>
            <Button onClick={() => setConfirmationDialog(false)} autoFocus>
              Não
            </Button>
          </DialogActions>
        </Dialog>
        <Historico
          show={showHistorico}
          onClose={() => setShowHistorico(false)}
        />
      </StyledRoot>
    </Container>
  );
}
