import React from "react";
import { styled } from "@mui/material/styles";
import MuiAvatar from "@mui/material/Avatar";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import generateUniqueId from "generate-unique-id";

import { usePessoaDocumento, avatarType } from "./PessoaDocumentoContext";

export const Avatar = ({ disabled }) => {
  const { addDocumentos, avatar, removeDocumento, showDocumento } =
    usePessoaDocumento();

  const upload = (event) => {
    const reader = new FileReader();

    reader.onloadend = () => {
      if (avatar) removeDocumento(avatar);
      event.target.files[0].data = reader.result;
      addDocumentos([
        {
          id: generateUniqueId(),
          documento: [event.target.files[0]],
          tipo: avatarType,
        },
      ]);
    };

    reader.readAsDataURL(event.target.files[0]);
  };

  return (
    <Root className={classes.container}>
      <input
        accept="image/*"
        className={classes.input}
        id="contained-button-file"
        multiple
        type="file"
        onChange={upload}
      />
      <label htmlFor={!disabled ? "contained-button-file" : undefined}>
        <MuiAvatar
          alt="Foto paciente"
          className={classes.button}
          sizes
          {...(!disabled
            ? {
                tabIndex: 0,
                src: showDocumento(avatar?.documento[0]),
                style: { cursor: "pointer" },
              }
            : {})}
        >
          <AddPhotoAlternateIcon />
        </MuiAvatar>
      </label>
    </Root>
  );
};

const PREFIX = "Avatar";

const classes = {
  input: `${PREFIX}-input`,
  button: `${PREFIX}-button`,
  container: `${PREFIX}-container`,
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.input}`]: {
    display: "none",
  },

  [`& .${classes.button}`]: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    backgroundColor: theme.palette.secondary.main,
  },

  [`&.${classes.container}`]: {
    marginBottom: theme.spacing(1),
  },
}));
