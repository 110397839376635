import React from "react";
import { DropzoneAreaBase } from "material-ui-dropzone";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from "@material-ui/icons/Visibility";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import generateUniqueId from "generate-unique-id";

import { useMessage } from "../Message/MessageContext";
import { usePessoaDocumento, tipoDocumento } from "./PessoaDocumentoContext";

export const Documentos = () => {
  const {
    documentos,
    addDocumentos,
    removeDocumento,
    updateTipoDocumento,
    showDocumento,
  } = usePessoaDocumento();
  const allDocumentos = [...documentos.saved, ...documentos.unsaved];
  const { setError } = useMessage();

  return (
    <>
      {allDocumentos.length > 0 && (
        <Box marginBottom={2}>
          <Table size="small">
            <TableBody>
              {allDocumentos.map((d, i) => (
                <TableRow hover key={i}>
                  <TableCell>{d.documento[0].name}</TableCell>
                  <TableCell>
                    <Autocomplete
                      options={tipoDocumento}
                      value={d.tipo}
                      fullWidth
                      onChange={(e, tipo) =>
                        updateTipoDocumento({
                          ...d,
                          tipo,
                        })
                      }
                      renderInput={(params) => (
                        <TextField {...params} label="Tipo" required />
                      )}
                    />
                  </TableCell>
                  <TableCell width={100}>
                    <IconButton
                      color="primary"
                      title="visualizar documento"
                      size="small"
                      disabled={!d.created_at}
                      component="a"
                      target="_blank"
                      href={showDocumento(d.documento[0])}
                    >
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton
                      color="secondary"
                      title="Remover documento"
                      size="small"
                      onClick={() => removeDocumento(d)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      )}
      <DropzoneAreaBase
        acceptedFiles={["image/*", "application/pdf"]}
        dropzoneText="Arraste o arquivo ou clique para adicionar novo documento"
        onDropRejected={(f, e) => {
          setError(
            <>
              Arquivo inválido <br />o arquivo excede o limit de upload{" "}
              <b>3MB</b> <br /> e/ou possui extensão inválida, extensões
              aceitas: <b>Imagens e pdf</b>
            </>
          );
        }}
        showAlerts={false}
        onAdd={(files) =>
          addDocumentos(
            files.map(({ file, data }) => {
              file.data = data;
              return {
                id: generateUniqueId(),
                documento: [file],
                tipo: "",
              };
            })
          )
        }
      />
    </>
  );
};
