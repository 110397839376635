import { AssistenciaSocial } from "./AssistenciaSocial";
import { TerapiaOcupacional } from "./TerapiaOcupacional";
import { Odontologia } from "./Odontologia/";
import { Fonoaudiologia } from "./Fonoaudiologia/";
import { usePessoa } from "../Pessoa/PessoaContext";
import { normalize } from "../../utils/normalize";

const mapper = {
  AssistenciaSocial,
  TerapiaOcupacional,
  Odontologia,
  Fonoaudiologia,
};

export const AtendimentoSetor = ({ setor }) => {
  const { pessoa, setPessoa } = usePessoa();
  const Comp = mapper[normalize(setor?.nome)];

  return pessoa && Comp ? <Comp pessoa={pessoa} setPessoa={setPessoa} /> : null;
};
