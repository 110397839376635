export const PessoaFonoaudiologiaDto = {
  dadoFamiliar: {
    paisParentes: undefined,
    criancaMaiorTempo: "",
  },
  gestacao: {
    desejada: undefined,
    desejadaGrau: "",
    altoRisco: "",
    porque: "",
    preNatal: undefined,
    preNatalConsultas: 0,
    medicamento: "",
    fumou: "",
    rubeola: "",
    mes: 0,
    ncf: "",
  },
  nascimento: {
    tipoParto: "", //list
    reacoesBebe: "", //list
    peso: "",
    oxigenio: "",
    incubadora: "", //mask
  },
  desenvolvimento: {
    firmouCabeca: 0,
    rolou: 0,
    sentou: 0,
    engatinhou: 0,
    andou: 0,
    sonoAtual: "", //list
    duranteSono: "", //list
    ouveBem: "",
    atendeNome: undefined,
    procuraSonora: undefined,
    localizaSonora: undefined,
    instrumentoMusical: "", //list
    enxergaBem: "",
    acompanhaObjeto: undefined,
    brincaSozinho: undefined,
    comOutras: undefined,
    brinquedo: undefined,
    ajudaAtividade: undefined,
    doencas: [],
    medicamento: undefined,
    cirurgia: "",
    hospitalizacao: undefined,
    hospitalizacaoTempo: 0,
    hospitalizacaoMotivo: "",
    succaoChupeta: undefined,
    tipoBico: "",
    succaoDedo: "",
    suga: [],
    mordeObjeto: undefined,
    roeUnha: undefined,
  },
  comunicacao: {
    primeirosSons: 0,
    primeirasPalavras: 0,
    falaCrianca: "", //list
    usaFrases: "",
    orderSimples: undefined,
    orderComplexas: undefined,
    gesturalOral: undefined,
    historiaFigura: "",
    cores: "",
    formas: "",
    tamanhos: "",
    esquemaCorporal: "",
    espacial: [],
    fezOntem: "",
    fazHoje: "",
    faraAmanha: "",
    listaPalavras: [], //duvida
    listaFiguras: [], //duvida
    quadroFonetico: "", //duvida
    voz: "", //list,
    labiosPosturaHabitual: "",
    labiosTonusSuperior: "",
    labiosTonusInferior: "",
    labiosFreios: "",
    labiosSensibilidade: "",
    labiosModalidade: "", //list
    linguaPosturaHabitual: "",
    linguaTonusSuperior: "",
    linguaTonusInferior: "",
    linguaFreios: "",
    linguaSensibilidade: "",
    linguaModalidade: "", //list
    bochechasTonus: "",
    bochechasModalidade: "", //list
    palatoDuro: "",
    palatoDuroModalidade: "",
    palatoMole: "", //list
    dentesCondicao: "", //list
    denticao: "", //list
    mordida: "", //list
    sialorreia: "",
  },
  alimentacao: {
    mamouSeio: "",
    mamadeira: "",
    bicoOrtodontico: undefined,
    furoAumentado: undefined,
    succao: "", //list
    mentalis: undefined,
    sugaCanudo: undefined,
    movimentoAssociado: undefined,
    alimentacaoPastosa: "",
    boaAceitacao: "",
    alimentosRejeitados: "",
    alimentacaoAtual: "",
    mastigacaoLabios: "", //list
    mastigacaoPedacos: "", //list
    mastigacaoMastiga: "", //list
    mastigacaoRuidosa: undefined,
    mastigacaoTipoPrensao: "", //list
    mastigacaoTrituracao: "", //list
    mastigacaoMandibula: undefined,
    mastigacaoMusculatura: "", //list
    mastigacaoMovimentos: "",
    degluticao: [],
    engasga: undefined,
    engasgaCom: "", //list
    escapeLiquido: undefined,
    restosAlimentares: undefined,
    tensaoLabial: undefined,
    contracaoMentalis: undefined,
    movimentoMastigacao: "",
    respiracao: "", //list
    sopro: "", //list
  },
  escolaridade: {
    escola: "",
    tipo: "", //list
    serie: "",
    atividadeProposta: "",
    dificuldade: "",
    relacionaCrianca: undefined,
    programaProposto: "",
  },
  complementar: "",
};
