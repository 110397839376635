import Grid from "@mui/material/Grid";
import { PessoaFonoaudiologiaDto } from "../dtos/PessoaFonoaudiologia.dto";
import { SectionCreator } from "./FildCreator";
import { TextFieldHourDay } from "../../../components/MaskedTextField";

export const Fonoaudiologia = ({ pessoa, setPessoa }) => {
  const newPessoa = {
    ...pessoa,
    fonoaudiologia: { ...PessoaFonoaudiologiaDto, ...pessoa.fonoaudiologia },
  };

  const setFonoaudiologia = (value, section, prop) =>
    setPessoa({
      ...newPessoa,
      fonoaudiologia: {
        ...newPessoa.fonoaudiologia,
        [section]: {
          ...newPessoa.fonoaudiologia[section],
          [prop]: value,
        },
      },
    });

  if (!pessoa || !setPessoa) return null;

  return (
    <Grid container spacing={3}>
      <SectionCreator
        title="Dados Familiares"
        sectionObject={newPessoa.fonoaudiologia.dadoFamiliar}
        onChange={(prop, value) =>
          setFonoaudiologia(value, "dadoFamiliar", prop)
        }
        mapperObject={mapper.dadoFamiliar}
        gridProps={(index) => ({ xs: 6, md: (index + 1) * 4 })}
        pessoaId={pessoa.id}
      />

      <SectionCreator
        title="Gestação"
        sectionObject={newPessoa.fonoaudiologia.gestacao}
        onChange={(prop, value) => setFonoaudiologia(value, "gestacao", prop)}
        mapperObject={mapper.gestacao}
        pessoaId={pessoa.id}
      />

      <SectionCreator
        title="Condições Nascimento"
        sectionObject={newPessoa.fonoaudiologia.nascimento}
        onChange={(prop, value) => setFonoaudiologia(value, "nascimento", prop)}
        mapperObject={mapper.nascimento}
        types={mapperTypes.nascimento}
        pessoaId={pessoa.id}
      />

      <SectionCreator
        title="Desenvolvimento Infantil"
        sectionObject={newPessoa.fonoaudiologia.desenvolvimento}
        onChange={(prop, value) =>
          setFonoaudiologia(value, "desenvolvimento", prop)
        }
        mapperObject={mapper.desenvolvimento}
        types={mapperTypes.desenvolvimento}
        pessoaId={pessoa.id}
      />

      <SectionCreator
        title="Alimentação"
        sectionObject={newPessoa.fonoaudiologia.alimentacao}
        onChange={(prop, value) =>
          setFonoaudiologia(value, "alimentacao", prop)
        }
        mapperObject={mapper.alimentacao}
        types={mapperTypes.alimentacao}
        pessoaId={pessoa.id}
      />

      <SectionCreator
        title="Escolaridade"
        sectionObject={newPessoa.fonoaudiologia.escolaridade}
        onChange={(prop, value) =>
          setFonoaudiologia(value, "escolaridade", prop)
        }
        mapperObject={mapper.escolaridade}
        types={mapperTypes.escolaridade}
        pessoaId={pessoa.id}
      />
    </Grid>
  );
};

const mapper = {
  dadoFamiliar: [
    "Pais são parentes entre si",
    "Com quem a criança passa a maior parte do tempo",
  ],
  gestacao: [
    "Gravidez desejada",
    "Qual grau",
    "Gravidez de alto risco",
    "Por que",
    "Fez Pré Natal",
    "Quantas consultas",
    "Medicamentos gestação",
    "Fumou durante",
    "Rubéola ou contato",
    "Qual mês",
    "Teve alguma NCF",
  ],
  nascimento: [
    "Tipo de parto",
    "Primeiras reação do bebe",
    "Peso ao nascimento",
    "Precisou de oxigênio",
    "Incubadora",
  ],
  desenvolvimento: [
    "Firmou a cabeça com",
    "Rolou com",
    "Sentou com",
    "Engatinhou com",
    "Andou com",
    "Sono atual",
    "Durante o sono",
    "Ouve bem",
    "Atende pelo nome",
    "Procura fonte sonora",
    "Localiza fonte sonora",
    "Instrumentos musicais",
    "Enxerga bem",
    "Acompanha o objeto",
    "Brinca sozinha",
    "Brinca com outras crianças",
    "Preferência por tipo de brinquedo",
    "Ajuda em atividades caseiras",
    "Doenças",
    "Medicação",
    "Fez cirurgia",
    "Hospitalização",
    "Tempo hospitalização",
    "Motivo hospitalização",
    "Sucção chupeta",
    "Tipo de bico",
    "Sucção dedo",
    "Suga",
    "Morde objetos",
    "Roe unhas",
  ],
  comunicacao: [],
  alimentacao: [
    "Mamou no seio",
    "Mamou na mamadeira",
    "Bico ortodontico",
    "Furo aumentado",
    "Sucção",
    "Participação dos mentalis",
    "Suga no canudo",
    "Movimentos lábios ou línguas",
    "Início alimentação pastosa",
    "Boa aceitação",
    "Alimentos rejeitados",
    "Alimentação atual",
    "Mastigação lábios",
    "Mastigação pedaços",
    "Mastiga",
    "Mastigação ruidosa",
    "Tipo de preensão",
    "Trituração",
    "Lateraliza a mandibula",
    "Musculatura contraída",
    "Movimentos associados",
    "Deglutição",
    "Engasga",
    "Engasga com",
    "Escape de líquido",
    "Restos alimentares",
    "Tensão labial",
    "Contração de mentalis",
    "Presença de movimentos associados",
    "Respiração",
    "Sopro",
  ],
  escolaridade: [
    "Frequenta escola ou creche",
    "Tipo",
    "Série",
    "Participa atividades propostas",
    "Sente dificuldades",
    "Relaciona-se com outras crianças",
    "Acompanha programa proposto",
  ],
};

const mapperTypes = {
  nascimento: {
    tipoParto: [
      "Normal",
      "Cesaria",
      "Fórceps",
      "Pré termo",
      "A termo",
      "Pós termo",
    ],
    reacoesBebe: ["Chorou logo", "Ficou roxinho", "Ictérico"],
    incubadora: TextFieldHourDay,
  },
  desenvolvimento: {
    sonoAtual: ["Tranquilo", "Agitado", "Contínuo"],
    duranteSono: ["Ronca", "Baba", "Range os dentes"],
    instrumentoMusical: ["Tambor", "Guizo", "Chocalho"],
    doencas: [
      "Sarampo",
      "Caxumba",
      "Desidratação",
      "Meningite",
      "Resfriados constantes",
      "Febre alta",
      "Convulsão",
      "Rubéola",
      "Alergias",
      "Problemas de ouvido",
    ],
    suga: ["Lábio Superior", "Lábio Inferior", "Bochechas", "Língua"],
  },
  alimentacao: {
    succao: ["Forte", "Fraca", "Rítmica", "Com escoamento"],
  },
  escolaridade: {
    tipo: ["Normal", "Especial", "Normal com classe especial"],
    succao: ["Forte", "Fraca", "Rítmica", "Com escoamento"],
    mastigacaoLabios: ["Fechados", "Abertos"],
    mastigacaoPedacos: ["Grandes", "Pequenos"],
    mastigacaoMastiga: ["Depressa", "Devagar", "Normal"],
    mastigacaoTipoPrensao: ["Frontal", "Lateral"],
    mastigacaoTrituracao: ["Anterios", "Lateral"],
    mastigacaoMusculatura: ["Temporal", "Mentalis", "Masseter"],
    engasgaCom: ["Sólido", "Líquido", "Pastoso"],
    respiracao: [
      "Nasal",
      "Bucal viciosa",
      "Bucal patológica",
      "Ruidosa",
      "Silenciosa",
      "Superior",
      "Inferior",
      "Mista",
    ],
    sopro: ["Direcionado", "Contínuo", "Fraco", "Esguicha saliva"],
  },
};
