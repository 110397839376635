import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";

const booleanOptions = [
  { label: "Sim", value: true },
  { label: "Não", value: false },
];

const getGridProps = (gridProps, ...params) => {
  if (!gridProps) return {};
  return typeof gridProps === "function" ? gridProps(...params) : gridProps;
};

export const SectionCreator = ({
  title,
  sectionObject,
  onChange,
  mapperObject,
  gridProps,
  ...rest
}) => {
  return (
    <>
      {title && (
        <Grid item xs={12}>
          <Typography component={Box} mb={-1} fontWeight="bold">
            {title}
          </Typography>
        </Grid>
      )}
      {Object.entries(sectionObject).map((keyValue, index) => (
        <FildCreator
          label={mapperObject[index]}
          key={index}
          keyValue={keyValue}
          onChange={onChange}
          xs={6}
          md={4}
          {...getGridProps(gridProps, index, keyValue)}
          {...rest}
        />
      ))}
    </>
  );
};

export const FildCreator = ({
  title,
  keyValue,
  onChange,
  label,
  types = {},
  pessoaId,
  ...rest
}) => {
  const valueType = typeof keyValue[1];
  const keyType = typeof types[keyValue[0]];
  const [localValue, setLocalValue] = useState("");

  useEffect(() => {
    setLocalValue(keyValue[1]);
  }, [pessoaId]);

  const onBlur = () => onChange(keyValue[0], localValue);

  const getElementType = () => {
    if (["number", "string"].includes(valueType) && !types[keyValue[0]]) {
      return (
        <TextField
          fullWidth
          onBlur={onBlur}
          onChange={(e) => setLocalValue(e.target.value)}
          value={valueType === "string" || localValue > 0 ? localValue : ""}
          label={label}
          type={valueType === "number" ? "number" : "text"}
        />
      );
    }

    if (keyType === "function") {
      const Comp = types[keyValue[0]];
      return (
        <Comp
          fullWidth
          onChange={(e) => setLocalValue(e.target.value)}
          value={localValue}
          label={label}
          onBlur={onBlur}
        />
      );
    }

    const options =
      types[keyValue[0]]?.map((i) => ({ label: i, value: i })) ||
      booleanOptions;

    return (
      <Autocomplete
        onBlur={onBlur}
        options={options}
        value={options.find((o) => o.value === localValue)}
        getOptionLabel={(o) => o.label}
        onChange={(_, e) => setLocalValue(e.value)}
        fullWidth
        renderInput={(params) => <TextField {...params} label={label} />}
      />
    );
  };

  return (
    <Grid item {...rest}>
      {getElementType()}
    </Grid>
  );
};
