import React from "react";
import MuiToolbar from "@mui/material/Toolbar";
import MuiAppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Typography from "@mui/material/Typography";
import clsx from "clsx";
import { Logout } from "./Logout";
import StyledRoot, { classes } from "./styles";

export const AppBar = ({ toggleDrawer, open }) => (
  <StyledRoot>
    <MuiAppBar
      position="absolute"
      className={clsx(classes.appBar, open && classes.appBarShift)}
    >
      <MuiToolbar className={classes.toolbar}>
        <IconButton
          edge="start"
          color="inherit"
          aria-label="open drawer"
          onClick={toggleDrawer}
          className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
        >
          <MenuIcon />
        </IconButton>
        <Typography
          className={classes.title}
          component="h1"
          variant="h6"
          color="inherit"
          noWrap
        >
          Dashboard
        </Typography>
        <Logout />
      </MuiToolbar>
    </MuiAppBar>
  </StyledRoot>
);
