import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import DatePicker from "@mui/lab/DatePicker";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import { differenceInYears, format } from "date-fns";
import { styled } from "@mui/material/styles";

import {
  TextFieldCPF,
  TextFieldTelefone,
  TextFieldSUS,
  TextFieldCEP,
} from "../../components/MaskedTextField";
import { useInit } from "../Router/InitContext";
import { PessoaHorarioEcolarDto } from "./PessoaContext";

export const PessoaForm = ({ pessoa, setPessoa }) => {
  const { bairros } = useInit();
  const [dtNascimento, setDtNascimento] = useState(pessoa.nascimento);
  const [dtError, setDtError] = useState(null);

  useEffect(() => {
    setDtNascimento(pessoa.nascimento);
    setDtError(null);
  }, [pessoa.id]);

  useEffect(() => {
    let nascimento = false;
    try {
      nascimento =
        dtNascimento === null ? null : format(dtNascimento, "yyyy-MM-dd");
    } catch {}

    if (nascimento !== false && !dtError)
      setPessoa({
        ...pessoa,
        nascimento,
      });
  }, [dtNascimento, dtError]);

  const dateHelperText = (() => {
    const age =
      !dtError && pessoa.nascimento
        ? differenceInYears(new Date(), new Date(pessoa.nascimento))
        : " ";
    if (typeof age !== "number") return age;
    return `${age} ${age === 1 ? "ano" : "anos"} de idade`;
  })();

  return (
    <>
      <Grid item xs={12} sm={8}>
        <TextField
          variant="outlined"
          required
          fullWidth
          onChange={(e) => setPessoa({ ...pessoa, nome: e.target.value })}
          value={pessoa.nome}
          label="Nome"
          autoFocus
        />
      </Grid>
      <DateContainer item xs={12} sm={4}>
        <DatePicker
          disableFuture
          inputVariant="outlined"
          label="Nascimento"
          inputFormat="dd/MM/yyyy"
          value={dtNascimento}
          defaultValue={null}
          renderInput={(p) => <TextField {...p} helperText={dateHelperText} />}
          onChange={setDtNascimento}
          onError={setDtError}
        />
      </DateContainer>
      <Grid item xs={12}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Sexo</FormLabel>
          <RadioGroup
            row
            value={pessoa.sexoMasculino}
            onChange={(e) =>
              setPessoa({
                ...pessoa,
                sexoMasculino: e.target.value === "true",
              })
            }
          >
            <FormControlLabel
              value={false}
              control={<Radio />}
              label="Feminino"
            />
            <FormControlLabel
              value={true}
              control={<Radio />}
              label="Masculino"
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField
          variant="outlined"
          fullWidth
          label="Nº Pront. Físico"
          value={pessoa.numeroProntuario}
          onChange={(e) =>
            setPessoa({ ...pessoa, numeroProntuario: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextField
          variant="outlined"
          fullWidth
          label="RG"
          value={pessoa.RG}
          onChange={(e) => setPessoa({ ...pessoa, RG: e.target.value })}
        />
      </Grid>
      <Grid item xs={6} md={4}>
        <TextFieldCPF
          variant="outlined"
          fullWidth
          label="CPF"
          value={pessoa.CPF}
          onChange={(e) => setPessoa({ ...pessoa, CPF: e.target.value })}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          fullWidth
          label="Nome pai"
          value={pessoa.nomePai}
          onChange={(e) => setPessoa({ ...pessoa, nomePai: e.target.value })}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          fullWidth
          label="Nome mãe"
          value={pessoa.nomeMae}
          onChange={(e) => setPessoa({ ...pessoa, nomeMae: e.target.value })}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="outlined"
          fullWidth
          label="Nome responsável"
          value={pessoa.responsavel}
          onChange={(e) =>
            setPessoa({ ...pessoa, responsavel: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12} sm={9}>
        <TextField
          variant="outlined"
          fullWidth
          label="Endereço"
          value={pessoa.endereco}
          onChange={(e) => setPessoa({ ...pessoa, endereco: e.target.value })}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextField
          variant="outlined"
          fullWidth
          label="Numero"
          value={pessoa.numeroEndereco}
          onChange={(e) =>
            setPessoa({ ...pessoa, numeroEndereco: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12} sm={9}>
        <Autocomplete
          options={bairros}
          value={pessoa.bairro}
          getOptionLabel={(option) => option.nome || ""}
          onChange={(e, bairro) => setPessoa({ ...pessoa, bairro })}
          fullWidth
          renderInput={(params) => (
            <TextField {...params} label="Bairro" variant="outlined" required />
          )}
        />
      </Grid>
      <Grid item xs={6} sm={3}>
        <TextFieldCEP
          variant="outlined"
          fullWidth
          label="CEP"
          value={pessoa.CEP}
          onChange={(e) => setPessoa({ ...pessoa, CEP: e.target.value })}
        />
      </Grid>
      <Grid item xs={6} sm={4}>
        <TextFieldTelefone
          variant="outlined"
          fullWidth
          label="Telefone"
          value={pessoa.telefone}
          onChange={(e) => setPessoa({ ...pessoa, telefone: e.target.value })}
        />
      </Grid>
      <Grid item xs={6} sm={4}>
        <TextFieldTelefone
          variant="outlined"
          fullWidth
          label="Celular"
          value={pessoa.celular}
          onChange={(e) => setPessoa({ ...pessoa, celular: e.target.value })}
        />
      </Grid>
      <Grid item xs={6} sm={4}>
        <TextFieldTelefone
          variant="outlined"
          fullWidth
          label="Recado"
          value={pessoa.telefoneRecado}
          onChange={(e) =>
            setPessoa({ ...pessoa, telefoneRecado: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={8} sm={9}>
        <TextField
          variant="outlined"
          fullWidth
          label="Diagnóstico"
          value={pessoa.diagnostico}
          onChange={(e) =>
            setPessoa({ ...pessoa, diagnostico: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={4} sm={3}>
        <TextField
          variant="outlined"
          fullWidth
          label="CID"
          value={pessoa.CID}
          onChange={(e) => setPessoa({ ...pessoa, CID: e.target.value })}
        />
      </Grid>
      <Grid item xs={6}>
        <TextFieldSUS
          variant="outlined"
          fullWidth
          label="Nº SUS Responsável"
          value={pessoa.numeroSUSResponsavel}
          onChange={(e) =>
            setPessoa({ ...pessoa, numeroSUSResponsavel: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={6}>
        <TextFieldSUS
          variant="outlined"
          fullWidth
          label="Nº SUS Reabilitando"
          value={pessoa.numeroSusReabilitando}
          onChange={(e) =>
            setPessoa({ ...pessoa, numeroSusReabilitando: e.target.value })
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Possui Plano de Saúde</FormLabel>
          <RadioGroup
            row
            value={pessoa.planoSaude}
            onChange={(e) =>
              setPessoa({
                ...pessoa,
                planoSaude: e.target.value === "true",
              })
            }
          >
            <FormControlLabel value={true} control={<Radio />} label="Sim" />
            <FormControlLabel value={false} control={<Radio />} label="Não" />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Autocomplete
          options={PessoaHorarioEcolarDto}
          value={pessoa.horarioEscolar || ""}
          onChange={(e, horarioEscolar) =>
            setPessoa({ ...pessoa, horarioEscolar })
          }
          fullWidth
          renderInput={(params) => (
            <TextField {...params} label="Horário escolar" variant="outlined" />
          )}
        />
      </Grid>
    </>
  );
};

const DateContainer = styled(Grid)({
  "& > *": {
    width: "100%",
  },
});
