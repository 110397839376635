import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import grey from "@mui/material/colors/grey";

const theme = {
  palette: {
    primary: {
      main: "#1d11bc",
    },
    secondary: {
      main: "#f50057",
    },
  },
  overrides: {
    MUIRichTextEditor: {
      editor: {
        minHeight: 100,
        border: `solid 1px ${grey[400]}`,
        borderRadius: 4,
        padding: "4px 8px",
      },
    },
  },
};

export const Theme = ({ children }) => (
  <ThemeProvider theme={createTheme(theme)}>{children}</ThemeProvider>
);
