import { AuthProvider } from "./Auth/AuthContext";
import { MessageProvider } from "./Message/MessageContext";
import React from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Router } from "./Router/Router";
import { Theme } from "./Theme/Theme";

const App = () => (
  <Theme>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <MessageProvider>
        <AuthProvider>
          <Router />
        </AuthProvider>
      </MessageProvider>
    </LocalizationProvider>
  </Theme>
);

export default App;
