import { useState, createContext, useContext } from "react";
import { useAuth } from "../Auth/AuthContext";
import { AtendimentoAnexos } from "../../api/api";

const PessoaAgendamentoAnexosContext = createContext({});

export const usePessoaAgendamentoAnexos = () =>
  useContext(PessoaAgendamentoAnexosContext);

export const AnexoTypes = {
  mapaDentes: "Mapa Dentes",
};

export function PessoaAgendamentoAnexosProvider({ children }) {
  const { user } = useAuth();
  const [isLoadingAnexos, setIsLoadingAnexos] = useState(false);
  const [anexos, setAnexos] = useState([]);

  const updateAnexos = async (agendamento = {}) => {
    if (!agendamento.id || !agendamento.dataAtendimento) return;

    const newAnexos = [...anexos];

    for (const anexo of newAnexos) {
      if (!anexo.unsaved) continue;

      anexo.atendimento = agendamento.id;
      anexo.unsaved = undefined;

      if (anexo.id) {
        await AtendimentoAnexos(user.jwt).update(anexo.id, anexo);
        continue;
      }

      const { data } = await AtendimentoAnexos(user.jwt).add(anexo);
      anexo.id = data.id;
    }

    setAnexos(newAnexos);
  };

  const loadAnexos = async (agendamento = {}) => {
    if (!agendamento.id || !agendamento.dataAtendimento) {
      setAnexos([]);
      return;
    }

    setIsLoadingAnexos(true);
    try {
      const { data } = await AtendimentoAnexos(user.jwt).get(agendamento.id);
      setAnexos(data);
    } catch {
      setAnexos([]);
    } finally {
      setIsLoadingAnexos(false);
    }
  };

  const value = {
    isLoadingAnexos,
    loadAnexos,
    updateAnexos,
    anexos: {
      mapaDentes: anexos.find((a) => a.tipo === AnexoTypes.mapaDentes),
    },
    clearAnexos: () => setAnexos([]),
    setAnexos: {
      mapaDentes: (anexo) => {
        const current = anexos.find((a) => a.tipo === AnexoTypes.mapaDentes);

        if (current) {
          setAnexos([
            ...anexos.filter((a) => a.tipo !== AnexoTypes.mapaDentes),
            {
              ...current,
              anexo,
              unsaved: true,
            },
          ]);
          return;
        }

        setAnexos([
          ...anexos,
          { anexo, unsaved: true, tipo: AnexoTypes.mapaDentes },
        ]);
      },
    },
  };

  return (
    <PessoaAgendamentoAnexosContext.Provider value={value}>
      {children}
    </PessoaAgendamentoAnexosContext.Provider>
  );
}
