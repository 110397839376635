import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { styled } from "@mui/material/styles";

import { usePessoa } from "../Pessoa/PessoaContext";
import { useDesligamento } from "./DesligamentoContext";

export const Historico = ({ show, onClose }) => {
  const { pessoa } = usePessoa();
  const { desligamentos } = useDesligamento();

  if (!show) return null;

  return (
    <Dialog
      open={true}
      onClose={onClose}
      scroll="paper"
      fullWidth
      maxWidth="md"
    >
      <DialogTitle>Histórico</DialogTitle>
      <Container>
        <DialogContentText mb={2}>
          Histórico de desligamentos e religamentos de <b>{pessoa.nome}</b>
        </DialogContentText>
        {desligamentos.map((item) => (
          <Grid container sx={{ borderTop: "solid 1px", pt: 2, mt: 2 }}>
            <Grid item md={4} xs={12}>
              Acão: <b>{item.religamento ? "religamento" : "desligamento"}</b>
            </Grid>
            <Grid item md={4} xs={12}>
              Data:{" "}
              <b>
                {format(new Date(item.data), "dd/MM/yyyy HH:mm", {
                  locale: ptBR,
                })}
              </b>
            </Grid>
            <Grid item md={4} xs={12}>
              Profissional: <b>{item.profissional.nome}</b>
            </Grid>
            <Grid item mt={2} xs={12}>
              <div>Motivo:</div>
              <div dangerouslySetInnerHTML={{ __html: item.motivo }} />
            </Grid>
          </Grid>
        ))}
      </Container>
      <DialogActions>
        <Button onClick={onClose} autoFocus color="primary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Container = styled(DialogContent)({
  "& p": {
    margin: 0,
  },
});
