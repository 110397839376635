import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import SaveIcon from "@material-ui/icons/Save";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import PrintIcon from "@material-ui/icons/Print";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { useMessage } from "../Message/MessageContext";
import StyledRoot, { classes } from "../../utils/pageStyles";
import { PessoaEmAtendimento } from "../Pessoa/PessoaEmAtendimento";
import { Avatar } from "../PessoaDocumento/Avatar";
import { Documentos } from "../PessoaDocumento/Documentos";
import { LoadingButton } from "../../components/Loading";
import { usePessoa, PessoaDto } from "./PessoaContext";
import { PessoaForm } from "./PessoaForm";
import { Odontologia } from "./Setor/Odontologia";
import { Fonoaudiologia } from "./Setor/Fonoaudiologia";
import { AssistenciaSocial } from "./Setor/AssistenciaSocial";

const dadosPorSetor = (pessoa, setPessoa) => [
  {
    title: "Documentos do Paciente",
    content: <Documentos />,
  },
  {
    title: "Avalição Social",
    content: <AssistenciaSocial pessoa={pessoa} setPessoa={setPessoa} />,
  },
  {
    title: "Avaliação Fonoaudiológica",
    content: <Fonoaudiologia pessoa={pessoa} setPessoa={setPessoa} />,
  },
  {
    title: "Avaliação Odontológica",
    content: <Odontologia pessoa={pessoa} setPessoa={setPessoa} />,
  },
];

export function Pessoa() {
  const { setInfo } = useMessage();
  const { savePessoa, pessoa, setPessoa, isLoading } = usePessoa();
  const location = useLocation();
  const navigate = useNavigate();
  const isInsert = location.pathname === "/novo-cadastro";

  const onSubmit = async (e) => {
    e.preventDefault();
    if ((await savePessoa(pessoa)) && isInsert) {
      navigate("/paciente");
    }
  };

  useEffect(() => {
    if (isInsert && pessoa.id) {
      setPessoa(PessoaDto);
      setInfo(
        `Atendimento com ${pessoa.nome} encerrado e iniciado novo cadastro`
      );
    }
  }, [location.pathname]);

  const pessoaNotSelected = !isInsert && !pessoa.id;

  return (
    <StyledRoot className={classes.paper}>
      <Avatar disabled={pessoaNotSelected} />
      <Typography component="h1" variant="h5">
        {isInsert ? "Cadastro" : "Atualização"} de paciente
      </Typography>

      {pessoaNotSelected && (
        <form className={classes.form}>
          <Grid container spacing={2}>
            <PessoaEmAtendimento />
          </Grid>
        </form>
      )}

      {!pessoaNotSelected && (
        <form className={classes.form} onSubmit={onSubmit}>
          <Grid container spacing={2}>
            <PessoaForm pessoa={pessoa} setPessoa={setPessoa} />
            <Grid item xs={12}>
              {dadosPorSetor(pessoa, setPessoa).map((dados, key) => (
                <Accordion TransitionProps={{ unmountOnExit: true }} key={key}>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography>{dados.title}</Typography>
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordion}>
                    {dados.content}
                  </AccordionDetails>
                </Accordion>
              ))}
            </Grid>
            <Grid item xs={6}>
              <LoadingButton
                loading={isLoading}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                className={classes.button}
                startIcon={<SaveIcon />}
              >
                Salvar
              </LoadingButton>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                variant="contained"
                size="large"
                onClick={() => window.print()}
                className={classes.button}
                startIcon={<PrintIcon />}
              >
                Imprimir
              </Button>
            </Grid>
          </Grid>
        </form>
      )}
    </StyledRoot>
  );
}
