import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Odontologia as OdontologiaPessoa } from "../../Pessoa/Setor/Odontologia";
import { Canvas } from "./Canvas";

export const Odontologia = ({ pessoa, setPessoa }) => (
  <>
    <Typography component={Box} mb={-1} fontWeight="bold">
      Anamnese
    </Typography>
    <OdontologiaPessoa pessoa={pessoa} setPessoa={setPessoa} />
    <Typography component={Box} mt={2} fontWeight="bold">
      Exame Clínico
    </Typography>
    <Canvas pessoa={pessoa} />
  </>
);
