import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";

import StyledRoot, { classes } from "../../utils/pageStyles";

export const NotFound = () => (
  <Container component="main" maxWidth="md">
    <StyledRoot className={classes.paper}>
      <Avatar className={classes.avatar}>
        <BrokenImageIcon />
      </Avatar>
      <Typography component="h1" variant="h5">
        Página não encontrada
      </Typography>
    </StyledRoot>
  </Container>
);
