import Grid from "@mui/material/Grid";
import { PessoaOdontologiaDto } from "../dtos/PessoaOdontologia.dto";
import { FildCreator } from "./FildCreator";

export const Odontologia = ({ pessoa, setPessoa }) => {
  const newPessoa = {
    ...pessoa,
    odontologia: { ...PessoaOdontologiaDto, ...pessoa.odontologia },
  };

  const setOdontologia = (prop, value) =>
    setPessoa({
      ...newPessoa,
      odontologia: {
        ...newPessoa.odontologia,
        [prop]: value,
      },
    });

  return pessoa && setPessoa ? (
    <Grid container spacing={3}>
      {Object.entries(newPessoa.odontologia).map((keyValue, index) => (
        <FildCreator
          xs={6}
          md={4}
          label={mapper[index]}
          key={index}
          keyValue={keyValue}
          onChange={setOdontologia}
          pessoaId={pessoa.id}
        />
      ))}
    </Grid>
  ) : null;
};

const mapper = [
  "Está sob cuidados médicos",
  "Remédio de uso diário",
  "Hospitalizado nos último anos",
  "Alergia a medicamento",
  "Problemas cardíacos",
  "Pressão Arterial alta",
  "Já teve febre reumática",
  "Problemas sanguíneos",
  "Problemas respiratórios",
  "Tem ou teve hepatite",
  "Tem diabetes",
  "Doença contagiosa",
  "É fumante",
  "Gengivite",
  "Periodontite",
  "Dentes ausentes",
  "Outros",
];
