export const PessoaAssistenciaSocialDto = {
  identificacao: {
    naturalDe: "",
    cartorio: "",
    registro: "",
    folha: "",
    livro: "",
    escolaridade: "", //duplicado fono
    religiao: "",
    matriculaACEC: "", //data
    problemas: "",
    procedencia: "",
    tratamento: "",
    transporte: "",
    acompanhante: "",
  },
  pais: [], //list of ResponsavelPessoaAssistenciaSocialDto
  irmaos: [], //list of IrmaosPessoaAssistenciaSocialDto
  antecedentes: {
    pessoaNervosa: undefined,
    manifesta: "",
    toxico: "",
    deficientes: "",
    bebe: "",
    doencas: "",
  },
  saude: {
    desejado: undefined,
    aborto: undefined,
    vicio: undefined,
    saude: undefined,
    preNatal: undefined, //duplicado fono
    concluiu: undefined,
    nasceuDe: "", //list
    partoHospitalar: undefined,
    assistido: "",
    assistenciaMedica: undefined,
    daContinuidade: undefined,
    vacinacao: "",
  },
  doencas: {
    doencas: [], //list
    outras: "",
    andouIdade: 0,
    falouIdade: 0,
    dormeBem: undefined,
    alimentaBem: undefined,
    enureseNoturna: undefined,
  },
  financeiro: [],
  convenios: "",
  relacionamento: {
    pais: "", //list
    paisPorque: "",
    mae: "", //list
    maePorque: "",
    pai: "", //list
    paiPorque: "",
    irmaos: "", //list
    irmaosPorque: "",
  },
  autoridade: "", //list
  obs: "", 
};

const ResponsavelPessoaAssistenciaSocialDto = {
  parentesco: "", //list
  nome: "",
  estadoCivil: "",
  nasc: "", //data
  escolaridade: "",
  trabalho: "",
  telCom: "",
  endRes: "",
  municipio: "",
  cep: "", //busca CEP
  tel: "",
};

const IrmaosPessoaAssistenciaSocialDto = {
  nome: "",
  parentesco: "", //list
  nasc: "", //data
  sexo: "", //list
  estadoCivil: "",
  trabalha: undefined,
  profissao: "",
  escolaridade: "",
};
