export default (storageKey) => ({
  getSession: () => {
    try {
      return JSON.parse(sessionStorage.getItem(storageKey));
    } catch (e) {
      return null;
    }
  },

  getStorage: () => {
    try {
      return JSON.parse(localStorage.getItem(storageKey));
    } catch (e) {
      return null;
    }
  },

  setStorage: (data) => {
    if (!data) return localStorage.removeItem(storageKey);
    localStorage.setItem(storageKey, JSON.stringify(data));
  },

  setSession: (data) => {
    if (!data) return sessionStorage.removeItem(storageKey);
    sessionStorage.setItem(storageKey, JSON.stringify(data));
  },
});
