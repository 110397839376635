import MuiCard from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Alert from "@mui/material/Alert";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import { Link } from "react-router-dom";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";
import { Box } from "@mui/material";
import { usePessoa } from "../Pessoa/PessoaContext";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const Card = ({
  title,
  classes,
  agendamentos = [],
  isLoading,
  dataFormat,
  generateLink = false,
}) => {
  const { getPessoaById, isLoading: isLoadingPessoa } = usePessoa();
  const [selectedIdAgendamento, setSelectedIdAgendamento] = useState(false);
  const navigate = useNavigate();

  const onClick = (agendamento) => async () => {
    if (selectedIdAgendamento) return true;

    setSelectedIdAgendamento(agendamento.id);
    getPessoaById(agendamento.pessoa.id);
  };

  useEffect(() => {
    if (selectedIdAgendamento && !isLoadingPessoa) {
      setSelectedIdAgendamento(false);
      navigate(`/atendimento/${selectedIdAgendamento}`);
    }
  }, [selectedIdAgendamento, isLoadingPessoa]);

  return (
    <MuiCard className={classes.root}>
      <CardContent sx={{ position: "relative" }}>
        <Typography variant="h6" component="h4">
          {title}
        </Typography>
        {!isLoading && (
          <Typography variant="body2">
            Total: <b>{agendamentos.length}</b>
          </Typography>
        )}
        {!!selectedIdAgendamento && <LinearProgress sx={{ mt: 1 }} />}
        <List sx={{ overflow: "auto", maxHeight: 300 }}>
          {isLoading && <LinearProgress />}

          {!isLoading &&
            agendamentos.map((agendamento, index) =>
              generateLink ? (
                <ListItemButton sx={{ p: 0 }} onClick={onClick(agendamento)}>
                  <ListItemContent
                    {...{
                      agendamento,
                      index,
                      dataFormat,
                      classes,
                      isLoading: !!selectedIdAgendamento,
                    }}
                  />
                </ListItemButton>
              ) : (
                <ListItemContent
                  {...{ agendamento, index, dataFormat, classes }}
                />
              )
            )}

          {!isLoading && agendamentos.length === 0 && (
            <ListItem disableGutters>
              <ListItemText
                primary={
                  <Alert variant="outlined" severity="warning">
                    Nenhum agendamento registrado
                  </Alert>
                }
              />
            </ListItem>
          )}
        </List>
      </CardContent>
      <CardActions>
        <Box marginLeft={1}>
          <Button
            color="primary"
            component={Link}
            to="/agendamento"
            size="small"
          >
            Novo Agendamento
          </Button>
        </Box>
      </CardActions>
    </MuiCard>
  );
};

const ListItemContent = ({ agendamento, index, dataFormat, classes }) => (
  <ListItem key={index} disableGutters>
    <ListItemText
      primary={agendamento.pessoa.nome}
      secondary={
        <>
          <Typography component="span" variant="body2" color="textPrimary">
            {format(new Date(agendamento.dataAgendamento), dataFormat, {
              locale: ptBR,
            })}
          </Typography>
          {agendamento.profissional?.nome &&
            ` com ${agendamento.profissional.nome}`}
          <Box marginTop={1} display="flex">
            <span
              className={classes.chip}
              style={{ backgroundColor: agendamento.setorAtendimento.color }}
            />
            <Typography variant="caption">
              {agendamento.setorAtendimento.nome}
            </Typography>
          </Box>
        </>
      }
    />
  </ListItem>
);
