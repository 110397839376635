import { styled } from "@mui/material/styles";
import Logo from "./cabecalho-logo.png";
import Text from "./cabecalho-text.png";

/** Cabecalho para impressão */
export const HeaderPrint = () => (
  <Container>
    <img src={Logo} className="logo" alt="logo" />
    <img src={Text} className="text" alt="text" />
  </Container>
);

const Container = styled("div")(({ theme }) => ({
  display: "none",
  textAlign: "center",
  position: "absolute",
  top: 0,
  width: "100%",
  border: `solid 2px ${theme.palette.grey[300]}`,
  padding: 4,
  paddingTop: 8,
  "& img": {
    height: "45px",
  },
  "& .logo": {
    opacity: 0.2,
    position: "absolute",
    left: 4,
    top: 8,
  },
  "@media print": {
    display: "block",
  },
  "@page": { margin: "1cm" },
}));
