import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import logo from "./logo.png";
import { Copyright } from "../../components/Copyright";

export function AcessoPaciente() {
  const [value, setValue] = useState("");
  const [option, setOption] = useState("Email");

  const onSubmit = (e) => {
    e.preventDefault();
    //TODO implement login and show agendamento;
  };

  return (
    <StyledContainer component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <img src={logo} alt="Logo" width={100} />
        <Typography component="h1" variant="h5">
          Prontuário Eletrônico
        </Typography>
        <form className={classes.form} onSubmit={onSubmit}>
          <FormControl component="fieldset">
            <FormLabel component="legend">Acessar com:</FormLabel>
            <RadioGroup
              row
              aria-label="position"
              name="position"
              defaultValue="end"
              value={option}
              onChange={(e) => setOption(e.target.value)}
            >
              <FormControlLabel
                control={<Radio color="primary" />}
                label="Email"
                value="Email"
              />
              <FormControlLabel
                control={<Radio color="primary" />}
                label="Prontuário"
                value="Prontuário"
              />
              <FormControlLabel
                control={<Radio color="primary" />}
                label="SUS"
                value="SUS"
              />
            </RadioGroup>
          </FormControl>

          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            required
            label={option}
            value={value}
            onChange={(e) => setValue(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Acessar
          </Button>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </StyledContainer>
  );
}

const PREFIX = "AcessoPaciente";

const classes = {
  paper: `${PREFIX}-paper`,
  form: `${PREFIX}-form`,
  submit: `${PREFIX}-submit`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },

  [`& .${classes.form}`]: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(4),
  },

  [`& .${classes.submit}`]: {
    margin: theme.spacing(3, 0, 2),
  },
}));
