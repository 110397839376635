import { createContext, useContext, useEffect, useState } from "react";
import { useAuth } from "../Auth/AuthContext";
import { useMessage } from "../Message/MessageContext";
import { Desligamento, Pessoa } from "../../api/api";
import { usePessoa } from "../Pessoa/PessoaContext";

export const DesligamentoContext = createContext({});

export function useDesligamento() {
  return useContext(DesligamentoContext);
}

export function DesligamentoProvider({ children }) {
  const { user } = useAuth();
  const { setSuccess, setError } = useMessage();
  const [isLoading, setIsLoading] = useState(false);
  const [desligamentos, setDesligamentos] = useState([]);
  const [ativo, setAtivo] = useState([]);
  const { pessoa, setPessoa } = usePessoa();

  useEffect(() => {
    if (!pessoa.id) setDesligamentos([]);
  }, [pessoa.id]);

  useEffect(() => {
    setAtivo(desligamentos[0]);
  }, [desligamentos.length]);

  const addDesligamento = async (d) => {
    setIsLoading(true);
    try {
      const { data } = await Desligamento(user.jwt).add({
        pessoa: pessoa.id,
        data: new Date(),
        ...d,
      });

      const { data: p } = await Pessoa(user.jwt).update(pessoa.id, {
        ...pessoa,
        desligada: !d.religamento,
      });

      setPessoa(p);

      setDesligamentos([data, ...desligamentos]);
      setSuccess(
        `Paciente ${d.religamento ? "religado" : "desligado"} com sucesso`
      );
      return data;
    } catch (e) {
      setError(
        `Problema ao tentar ${d.religamento ? "religar" : "desligar"} paciente`
      );
    } finally {
      setIsLoading(false);
    }
  };

  const loadDesligamentos = async () => {
    setIsLoading(true);
    try {
      const { data } = await Desligamento(user.jwt).get(pessoa.id);
      setDesligamentos(data);
      return data;
    } catch (e) {
      setError("Problema ao carregar histórico de desligamentos");
      setDesligamentos([]);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const value = {
    ativo,
    isLoading,
    desligamentos,
    loadDesligamentos,
    addDesligamento,
  };

  return (
    <DesligamentoContext.Provider value={value}>
      {children}
    </DesligamentoContext.Provider>
  );
}
