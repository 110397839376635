import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import DatePicker from "@mui/lab/DatePicker";
import Alert from "@mui/material/Alert";

import { usePessoa } from "../Pessoa/PessoaContext";
import { ProfissionalSetor } from "../ProfissionalSetor/ProfissionalSetor";
import { LoadingButton } from "../../components/Loading";

import { usePessoaAgendamento } from "./PessoaAgendamentoContext";
import { Recorrencia } from "./Recorrencia";
import { useState } from "react";

export const Cadastrar = ({ open, onClose, id }) => {
  const label = id ? "Atualizar" : "Novo";
  const { saveAgendamento, agendamento, setAgendamento, isLoading } =
    usePessoaAgendamento();
  const { pessoa } = usePessoa();
  const [isValid, setIsValid] = useState(true);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (agendamento.dataAgendamento < new Date()) {
      return setIsValid(false);
    }

    setIsValid(true);
    await saveAgendamento();
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>{label} agendamento</DialogTitle>
      <form onSubmit={onSubmit}>
        <DialogContent sx={{ paddingTop: 0 }}>
          <DialogContentText sx={{ marginBottom: 2 }}>
            {label} agendamento para {pessoa.nome}
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
              <DatePicker
                autoOk
                fullWidth
                autoFocus
                ampm={false}
                disablePast
                value={agendamento.dataAgendamento}
                onChange={(dataAgendamento) =>
                  setAgendamento({ ...agendamento, dataAgendamento })
                }
                inputFormat="dd/MM/yyyy HH:mm"
                inputVariant="outlined"
                label="Data e hora"
                renderInput={(p) => <TextField required {...p} />}
              />
            </Grid>

            <ProfissionalSetor
              gridSetorProps={{ xs: 12, md: 4 }}
              gridProfissionalProps={{ xs: 12, md: 5 }}
              setorAtendimento={agendamento.setorAtendimento}
              onChangeSetor={(e, setorAtendimento) =>
                setAgendamento({ ...agendamento, setorAtendimento })
              }
              profissional={agendamento.profissional}
              onChangeProfissional={(e, profissional) =>
                setAgendamento({ ...agendamento, profissional })
              }
            />

            <Recorrencia />

            {!isValid && (
              <Grid item xs={12}>
                <Alert severity="warning">
                  A data de agendamento deve ser no futuro
                </Alert>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={isLoading} type="submit" color="primary">
            Confirmar
          </LoadingButton>
          <Button onClick={onClose}>Cancelar</Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
