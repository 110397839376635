import React from "react";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

export const LoadingButton = ({ loading, ...props }) => (
  <Box sx={{ position: "relative" }}>
    <Button disabled={loading} {...props} />
    {loading && <StyledCircularProgress size={24} />}
  </Box>
);

const StyledCircularProgress = styled(CircularProgress)({
  color: green[500],
  position: "absolute",
  top: "50%",
  left: "50%",
  marginTop: -12,
  marginLeft: -12,
});
